<template>
	<section class="container">
		<b-form-input class="input col-12" v-model="globalfilter" placeholder="Søg" />

		<div class="row">
			<div class="col-12">
				<BaseTable :items="users" :fields="fields" :isBusy="isBusy">
					<template v-slot:cell(fullName)="data">
						<router-link :to="{ name: 'User', params: { id: data.item.id } }"> {{ data.item.firstName }} {{ data.item.lastName }} </router-link>
					</template>
					<template v-slot:cell(agreements)="data">
						<template v-for="item in data.item.policies">
							<router-link
								:to="{
									name: 'CompanyEmployeeInfo',
									params: {
										companyId: item.companyId,
										employeeId: data.item.id,
										policyId: item.policyId,
									},
								}"
							>
								{{ company(item.companyId) }}
							</router-link>
						</template>
					</template>
				</BaseTable>
			</div>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import i18n from '@/plugins/i18n'

export default {
	name: 'CompanyList',
	components: {
		BaseTable,
	},
	data() {
		return {
			globalfilter: '',
			isBusy: false,
			fields: [
				{
					key: 'fullName',
					label: i18n.t('global.user.name'),
					sortable: true,
				},
				{
					key: 'cpr',
					label: i18n.t('global.user.cpr'),
					sortable: true,
				},
				{
					key: 'email',
					label: i18n.t('global.user.email'),
					sortable: true,
				},
				{
					key: 'phone',
					label: i18n.t('global.user.phone'),
					sortable: true,
				},
				{
					key: 'agreements',
					label: 'Aktive aftaler',
				},
			],
		}
	},
	async mounted() {
		this.$emit('updateLoader')
		this.isBusy = true
		// Load companies
		await this.$store.dispatch('user/getUsers') // Load data fra api+
		this.isBusy = false
	},
	methods: {
		company(id) {
			try {
				var company = this.companies.find((company) => company.id === id)
				return company?.companyInfo?.name
			} catch (error) {
				console.log(error)
			}
		},
	},
	computed: {
		users() {
			var users = this.$store.getters['user/users']
			var filter = this.globalfilter.toLowerCase()
			console.log(users)
			return users
				.sort((a, b) => (a.fullName > b.fullName ? 1 : -1))
				.filter((obj) => {
					if (this.globalfilter == '') {
						return true
					} else {
						return Object.keys(obj).some((key) => {
							try {
								var val = obj[key] ?? ''
								var filtered = JSON.stringify(val).toLowerCase()
								return filtered?.includes(filter)
							} catch (err) {
								console.log(err)
							}
						})
					}
				})
		},
		companies() {
			return this.$store.getters['company/companies']
		},
	},
}
</script>

<style lang="scss"></style>
